import useIsIpad from '../../hooks/useIsIpad';
import { NextRouter, useRouter } from 'next/router';
import { MobileOnlyAsset } from 'types/headerNav';
import { ICountryLanguage } from 'components/HeaderNavigation/components/DesktopNavigation/types';
import { SearchService } from 'service/search/searchService';
import useScreenWidth from '../../hooks/useScreenWidth';
import dynamic from 'next/dynamic';
import { SkeletonNavigation } from './components/SkeletonNavigation/SkeletonNavigation';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { AVAILABLE_THEMES_TYPE } from '@/styles/constants';

const DesktopNavigation = dynamic(
  () => import('./components/DesktopNavigation').then((m) => m.DesktopNavigation),
  {
    loading: SkeletonNavigation,
  },
);

const MobileNavigation = dynamic(
  () => import('./components/MobileNavigation').then((m) => m.MobileNavigation),
  {
    loading: SkeletonNavigation,
  },
);

// TODO  - Update types
interface IMainHeader {
  themeName?: AVAILABLE_THEMES_TYPE;
  headerNavData: any;
  hideNavigation?: boolean;
  searchData: any;
  popularSearch: any;
  featuredCardCarousel?: any;
  logo?: { url?: string };
  mobileOnlyData: MobileOnlyAsset | null;
  countryLanguages: ICountryLanguage[];
  locale: string;
  router?: NextRouter; // TODO: remove (left it there that it doesn't break other implementations)
}

const MainHeader = ({
  headerNavData,
  hideNavigation = false,
  searchData,
  popularSearch,
  featuredCardCarousel = '',
  logo,
  mobileOnlyData,
  countryLanguages,
  locale,
  themeName,
}: IMainHeader) => {
  const { pathTranslations } = useGlobalContext();
  const router = useRouter();
  const screenSize = useScreenWidth();
  const iPad = useIsIpad();

  if (!screenSize) return <SkeletonNavigation />;

  if (screenSize > 1023 && !iPad) {
    return (
      <DesktopNavigation
        data={headerNavData}
        hideMenu={hideNavigation}
        searchData={searchData}
        popularSearchData={popularSearch}
        searchFeaturedCarousel={featuredCardCarousel}
        searchSubmitHandler={(searchValue: string) => {
          SearchService.searchSubmitHandler(searchValue, pathTranslations.search, router);
        }}
        logo={logo}
        trackingEvents={{
          primary: TrackingService.primaryLink,
          secondary: TrackingService.secondaryLink,
          tertiary: TrackingService.tertiaryLink,
          clickPopularSearch: TrackingService.clickPopularSearch,
          clickSuggestion: TrackingService.clickSuggestion,
          clickSearchAllSuggestions: TrackingService.clickSearchAllSuggestions,
        }}
        countryLanguages={countryLanguages}
        locale={locale}
        themeName={themeName}
      />
    );
  }

  return (
    <>
      <MobileNavigation
        data={headerNavData}
        mobileOnlyData={mobileOnlyData}
        hideMenu={hideNavigation}
        searchData={searchData}
        popularSearchData={popularSearch}
        searchFeaturedCarousel={featuredCardCarousel}
        logo={logo}
        searchSubmitHandler={(searchValue: string) => {
          SearchService.searchSubmitHandler(searchValue, pathTranslations.search, router);
        }}
        trackingEvents={{
          primary: TrackingService.primaryLink,
          secondary: TrackingService.secondaryLink,
          tertiary: TrackingService.tertiaryLink,
          clickPopularSearch: TrackingService.clickPopularSearch,
          clickSuggestion: TrackingService.clickSuggestion,
          clickSearchAllSuggestions: TrackingService.clickSearchAllSuggestions,
        }}
        countryLanguages={countryLanguages}
        locale={locale}
      />
    </>
  );
};

export default MainHeader;
